<template>
  <div id="quotation-submit">
    <!--    <van-nav-bar-->
    <!--      title="切换石种"-->
    <!--      left-arrow-->
    <!--      @click-left="goBack"-->
    <!--    />-->
    <quote-card :isShowInput="isShowInput" :dataList="list" :isShowArrow="isShowArrow"
                :endInquiryId="query.endInquiryId"/>
  </div>
</template>

<script>
import {useRoute} from "vue-router";
import {onMounted, reactive, toRefs} from "vue";
import quoteCard from "./component/quoteCard.vue";
import {getEngineeringInfo, getEngineeringInfoNoToken} from '@/api/quitedPrice'

const {Session} = require("../../utils/storage");

export default {
  name: "stoneList",
  components: {quoteCard},

  setup() {
    const route = useRoute();
    const state = reactive({
      isShowInput: false, // 是否展示价格输入框
      list: [],
      isShowArrow: true,
      query: {
        endInquiryId: '',
      },
    })
    const getStoneList = () => {
      getEngineeringInfo(state.query).then(res => {
        state.list = res.data.liEngineeringItems
      })
    }
    const getEngineeringNoTokenFn = () => {
      getEngineeringInfoNoToken(state.query).then(res => {
        state.list = res.data.liEngineeringItems
      })
    }
    const goBack = () => {
      window.history.go(-1);
    }
    onMounted(() => {
      window.document.title = '切换石种';
      // state.query.engineeringId = route.params.engineeringId
      // state.query.engineeringItemId = route.params.engineeringItemId
      console.log(route)
      state.query.endInquiryId = route.query.endInquiryId;
      Session.get('token') ? getStoneList() : getEngineeringNoTokenFn()
    })
    return {
      goBack,
      getStoneList,
      ...toRefs(state),
    }
  }
}
</script>

<style lang="less" scoped>
#quotation-submit {
  width: 100vw;
  height: 100vh;
  //background: #F8F9FD;
}
</style>
